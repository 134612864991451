<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_streaming') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="list-group-item-accent-info m-0 p-0">
                        <b-list-group-item>
                            <div class="row m-0 text-muted align-items-center">
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-swimmer fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('service') }}</small><br />
                                    <strong>{{ service.name }}</strong>
                                </div>
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-map-marker-alt fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('location') }}</small><br />
                                    <strong>{{ location.name }}</strong>
                                </div>
                            </div>
                        </b-list-group-item>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input square dense color="primary" :placeholder="translate('enter_streaming_name')" type="text" v-model="streaming.name" />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="translate('date')" square dense readonly color="primary" id="date" v-model="streaming.date">
                                    <template v-slot:append>
                                        <q-icon name="event"></q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col p-0">
                                <q-input :label="translate('start_time')" color="primary" square dense v-model="streaming.start_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time class="time-menu" v-model="streaming.start_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col p-0">
                                <q-input :label="translate('end_time')" color="primary" square dense v-model="streaming.end_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time class="time-menu" v-model="streaming.end_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-select :class="!$q.screen.lt.sm ? 'm-0' : ''" flat square color="info" :label="translate('select_video_provider')" :options="video_provider_options" v-model="streaming.video_provider">
                                    <template v-slot:prepend>
                                        <i class="fas fa-video"></i>
                                    </template>
                                </q-select>
                            </div>
                            <div class="col-md p-0">
                                <q-input square color="primary" :placeholder="translate('video_link')" type="text" v-model="streaming.video_link" />
                            </div>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="pt-2 pb-2 font-weight-bold">
                        {{ translate('providers') }}
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info pt-1">
                        <div class="font-weight-bold mt-1">{{ translate('included_providers') }} ({{ streaming_included_providers.length }})</div>
                        <template v-if="streaming_included_providers && streaming_included_providers.length">
                            <q-item class="bordered-item" clickable ripple v-for="(provider,i) in streaming_included_providers" :key="'included-provider-' + i + provider.id">
                                <q-item-section avatar>
                                    <q-avatar class="shadow-2">
                                        <img v-if="provider && provider.image_link" :src="provider.image_link">
                                        <i v-else class="fas fa-user fa-1x text-muted"></i>
                                    </q-avatar>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ provider.name }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click.stop.prevent="deleteStreamingProviderFromIncluded(provider, 'provider')" flat color="danger" />
                                </q-item-section>
                            </q-item>
                        </template>
                        <div>
                            <div class="p-t2 mt-2 mb-2 text-primary bg-light">
                                <q-icon name="add" />&nbsp;
                                {{translate('add_provider')}}
                            </div>
                            
                            <div v-if="!workers.length || workers.length === 0" class="text-muted">{{ translate('no_results') }}</div>
                            <div v-else class="text-muted">{{ translate('results_found') }}: {{ workers.length }}</div>

                            <q-input class="search-input mb-0" @input="isTypingWorker = true" v-model="searchWorker" id="filternameitem" type="text" :placeholder="translate('find_worker')" :dense="true">
                                <template v-slot:prepend>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                            <div class="items-container list-container-scroll vh-31" :class="'list_wraper-streaming-' + streaming.id">
                                <q-item tag="label" class="list-item full-width avatar-red-hover" clickable ripple v-for="(worker,i) in workers" :key="'list-item-' + i + '-' + worker.worker_id">
                                    <q-item-section avatar>
                                        <q-avatar class="shadow-2">
                                            <img v-if="worker && worker.image_link" :src="worker.image_link">
                                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                                        </q-avatar>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ worker.name }}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section side>
                                        <q-btn icon="add" @click.stop.prevent="includeWorker(worker)" dense flat color="primary" />
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading @infinite="getWorkers" ref="infiniteLoading" :identifier="'to-include-workers-streaming-' + streaming.id + '-' +infWorkers" slot="append" />
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerStreaming">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from '../../../../main'
import {
    QTime,
    QDate
} from 'quasar'

export default {
    name: 'AddStreaming',
    props: ['service', 'location', 'event'],
    components: {
        QTime,
        QDate
    },
    created: function () {
        this.getVideoProviders()
        this.setDefaultDate()
        this.setDefaultTime()
        this.getServiceInfo()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    watch: {
        searchWorker: _.debounce(function () {
            this.isTypingWorker = false
        }, 200),
        isTypingWorker: function (value) {
            if (!value) {
                this.resetMainListWorkers()
            }
        }
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            workers: [],
            page_workers: 1,
            state: {},
            infWorkers: +new Date(),
            isTypingWorker: false,
            searchWorker: '',
            streaming_included_providers: [],
            video_provider_options: [],
            streaming: {
                name: null,
                date: '',
                start_time: '',
                end_time: '',
                video_link: null,
                video_provider: [],
                worker_ids: []
            },
            msg: '',
        };
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        resetMainListWorkers: function () {
            this.page_workers = 1
            this.workers = []
            this.infWorkers++
        },
        getWorkers: function ($state) {
            var url = baseUrl + 'services/providers?service_id=' + this.service.id + '&page=' + this.page_workers + '&items_per_page=' + this.$items_per_page
            if (this.searchWorker) {
                url += '&search=' + this.searchWorker
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.workers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['id'] = entity.worker_id
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            array['main_image_link'] = entity.main_image_link ? baseUrl + entity.main_image_link : null

                            return array
                        }))
                        if (response.data.next_page === true) {
                            this.page_workers++
                            $state.loaded()
                        } else {
                            $state.complete()
                            if (this.streaming_included_items && this.streaming_included_items.length) {
                                this.streaming_included_items.map(included_id => {
                                    if (this.workers.filter((item) => {
                                            item.id === included_id
                                        })) {
                                        // Find index of specific object using findIndex method,
                                        var objIndex = this.items.findIndex((obj => obj.id === included_id))
                                        // and remove the element from the items array.
                                        this.items.splice(objIndex, 1)
                                    }
                                })
                            }
                        }
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        includeWorker: function (item) {
            this.streaming_included_providers.push(item)
            this.deleteFromTOIncludeList(item.id)
            return false
        },
        addTOToIncludeList: function (item) {
            this.workers.push(item)
            return false
        }, 
        deleteFromTOIncludeList: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.workers.findIndex((obj => obj.id === id))
            // and remove the element from the workers array.
            this.workers.splice(objIndex, 1)
        },
        getVideoProviders: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = baseUrl + 'streaming_sessions/video_providers'

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.video_provider_options = response.data.items.map(entity => {
                        let array = entity
                        array = {
                            value: entity,
                            label: entity
                        }
                        return array
                    })
                    if (this.video_provider_options[0]) {
                        this.streaming.video_provider = this.video_provider_options[0]
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        setSelectableEndDays: function (date) {
            var dayWeekIndex = new Date(date).getDay()
            return date => new Date(date).getDay() === dayWeekIndex
        },
        setDefaultDate: function () {
            this.streaming.date = this.event.date
        },
        getServiceInfo: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'services/' + this.service.id, {
                    headers: headers
                })
                .then(response => {
                    var service = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        setDefaultTime: function () {
            var subtime = ':00'
            if (this.event.minute > 30) {
                subtime = ':30'
            }
            var meridian = ' AM'
            if (this.hour >= 12) {
                meridian = ' PM'
            }
            meridian = ''
            var event_hour = parseInt(this.event.hour)
            var event_start_hour = event_hour < 10 ? '0' + event_hour : event_hour
            var event_end_hour = (event_hour + 1) < 10 ? '0' + (event_hour + 1) : (event_hour + 1)

            this.streaming.start_time = event_start_hour + subtime + meridian
            this.streaming.end_time = event_end_hour + subtime + meridian
        },
        registerStreaming: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            var data = {
                'name': this.streaming.name,
                'service_id': this.service.id,
                'location_id': this.location.id,
                'date': this.streaming.date,
                'start_time': this.streaming.start_time + ':00',
                'end_time': this.streaming.end_time + ':00',
                'video_link': this.streaming.video_link,
                'video_provider': this.streaming.video_provider.label,
            }
            var worker_ids = []
            if (this.streaming_included_providers && this.streaming_included_providers.length) {
                this.streaming_included_providers.forEach(element => {
                    worker_ids.push(element.id)
                })
            }
            data['worker_ids'] = worker_ids

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'streaming_sessions', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg;
                    if (response.data.status) {
                        var message = 'add_streaming_' + this.msg
                        var translated_message = this.translate(message)
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                        eventBus.$emit('update_streamings')
                    } else {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'add_streaming_' + this.msg + key
                        var translated_message = this.translate(message)
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        deleteStreamingProviderFromIncluded: function (item, item_type) {
            if (item_type === 'provider') {
                var objIndex = this.streaming_included_providers.findIndex((obj => obj.id === item.id))
                this.streaming_included_providers.splice(objIndex, 1)
                this.addTOToIncludeList(item)
            }
            return false
        },
        addProvidersToList: function (items) {
            this.streaming_included_providers = items && items.length ? items : []
            return false
        },        
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
