<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-item style="min-height: 0;" class="p-0">
                    <q-item-section>
                        <q-item-label line="1"><q-icon name="fas fa-film" />&nbsp;{{ translate('streaming_session') }}</q-item-label>
                        <q-item-label line="2"><i class="fas fa-video text-muted"></i>&nbsp;<strong class="text-primary">{{ streaming.name }}</strong></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn dense class="pull-right" icon="fullscreen" id="doFullscreen" @click.stop.prevent="doFullscreen" />
                    </q-item-section>
                </q-item>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="p-0">
                    <template v-if="formattedVideoLink(streaming.video_provider, streaming.video_link)">
                        <div class="videoWrapper">
                            <iframe id="streamingVideoIframe" autoplay width="100%" height="auto" class="iframe-video" :src="formattedVideoLink(streaming.video_provider, streaming.video_link)" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                        </div>
                    </template>
                    <template v-else>
                        <div class="p-3 text-accent">{{ translate('sbscription_required_for_video_streaming') }}</div>
                    </template>
                    <div class="row m-0 align-items-center p-1 pl-3">
                        <div class="text-primary col-2 col-md-auto p-0 text-center"><i class="fas fa-video fa-2x"></i></div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('streaming_name') }}</small><br />
                            <strong class="text-primary">{{ streaming.name }}</strong>
                        </div>
                        <div class="text-muted col-2 col-md-auto p-0 text-center"><i class="fas fa-swimmer fa-2x" style="font-size: 1.25rem; padding-right: 0.1rem;"></i></div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('service') }}</small><br />
                            <strong class="text-dark">{{ service.name }}</strong>
                        </div>
                        <div class="text-muted col-2 col-md-auto p-0 text-center"><i class="fas fa-map-marker-alt fa-2x" style="padding-right: 0.25rem;"></i></div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('location') }}</small><br />
                            <strong class="text-dark">{{ location.name }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item class="p-1 pl-3 pr-0">
                    <div class="row m-0 align-items-center">
                        <div class="text-muted col-2 col-md-auto p-0 text-center"><i class="ml-1 mr-1 fas fa-calendar fa-2x"></i></div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('date') }}</small><br />
                            <strong class="text-dark">{{ prettyDate(streaming.date) }}</strong>
                        </div>
                        <div class="text-muted col-2 col-md-auto p-0 text-center"><i class="fas fa-clock fa-2x"></i></div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('start_time') }}</small><br />
                            <strong class="text-dark">{{ streaming.start_time }}</strong>
                        </div>
                        <div class="text-muted col-2 col-md-auto p-0 text-center"><i class="fas fa-clock fa-2x"></i></div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('end_time') }}</small><br />
                            <strong class="text-dark">{{ streaming.end_time }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item class="p-1 pl-3 pr-0">
                    <div class="row m-0 align-items-center">
                        <div class="text-primary col-2 col-md-auto p-0 text-center">
                            <i v-if="streaming.video_provider === 'vimeo'" class="fab fa-vimeo-v fa-2x mr-1" style="color: #1EB8EB"></i>
                            <i v-if="streaming.video_provider === 'youtube'" class="fab fa-youtube-y fa-2x mr-1" style="color: #FF0000"></i>
                        </div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('video_provider') }}</small><br />
                            <strong>{{ streaming.video_provider }}</strong>
                        </div>
                        <div class="text-muted col-2 col-md-auto p-0 text-center" :class="!$q.screen.lt.sm ? 'ml-2 mr-1' : ''"><i class="fas fa-link fa-2x" style="font-size: 1.25rem; padding-right: 0.1rem;"></i></div>
                        <div class="col-10 col-md">
                            <small class="text-muted">{{ translate('video_link') }}</small><br />
                            <strong class="text-dark">{{ streaming.video_link }}</strong>
                        </div>
                        <div class="col-2 col-md-auto"></div>
                        <div class="col-10 col-md"></div>
                    </div>
                    <div class="mt-2 font-weight-bold">{{ translate('providers') }}</div>
                    <q-chip class="p-0 mr-2 shadow-2" color="light" v-for="(worker, windex) in streaming.workers" :key="'streaming-provider-worker-' + windex + '-' + worker.id">
                        <q-avatar class="shadow-2">
                            <img v-if="worker.image_link" class="black-and-white" :src="worker.image_link">
                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                        </q-avatar>
                        {{ worker.worker_name }}
                    </q-chip>
                    <span v-if="!streaming.workers || streaming.workers.length === 0">-</span>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn @click.prevent="showDeleteStreamingPanel" type="button" no-caps color="danger">
                                <q-icon size="1rem" name="delete" class="mr-1" /> {{ translate('delete') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    QTime,
    QDate
} from 'quasar'
import DeleteStreaming from '../forms/DeleteStreaming'
import { eventBus } from '../../../../main'

export default {
    name: 'StreamingInfo',
    props: ['service', 'location', 'event'],
    components: {
        QTime,
        QDate
    },
    created: function () {
        this.setInitialValues()
        this.getStreaming()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    data: function() {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            streaming: {
                name: null,
                is_active: true,
                date: '',
                start_time: '',
                end_time: '',
                streaming_id: 0,
                provider_link: null,
                video_provider: null
            },
            msg: '',
        }
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        doFullscreen: function () {
            var iframe = document.querySelector('#streamingVideoIframe');
            // Do fullscreen
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen()
            } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullscreen()
            } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen()
            } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen()
            }
        },
        formattedVideoLink: function (platform, link) {
            if (platform && link) {
                var formatted_link = ''
                if (platform === 'vimeo') {
                    formatted_link = this.vimeoFormattedVideoLink(link)
                }
                if (platform === 'youtube') {
                    formatted_link = this.youtubeFormattedVideoLink(link)
                }
                if (formatted_link) {
                    return formatted_link
                }
            }
            return false
        },
        vimeoFormattedVideoLink: function (link) {
            var formatted_link = ''
            if (link) {
                formatted_link = link.substr(link.lastIndexOf('/') + 1)
            }
            return 'https://player.vimeo.com/video/' + formatted_link
        },
        youtubeFormattedVideoLink: function (link) {
            return link.split('/watch?v=').join('/embed/') + '?&autoplay=1'
        },
        getStreaming: function () {
            var url = baseUrl + 'streaming_sessions/' + this.event.streaming_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.streaming = response.data.item
                    if (response.data.item && response.data.item.workers && response.data.item.workers.length) {
                        this.streaming.workers = this.streaming.workers.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        })
                    }
                }).catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        setSelectableEndDays: function(date) {
            var dayWeekIndex = new Date(date).getDay()
            return date => new Date(date).getDay() === dayWeekIndex
        },
        setInitialValues: function () {
            this.streaming.date = this.event.date
            this.streaming.start_time = this.event.start_time
            this.streaming.end_time = this.event.end_time
            this.streaming.streaming_id = this.event.streaming_id
        },
        registerStreaming: function () {
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            var data = {
                'date': this.streaming.date
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'streaming_sessions/' + this.streaming.id, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_streaming_' + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        showDeleteStreamingPanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteStreaming,
                props: {
                    streaming_id:  this.event.streaming_id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    padding: 0.5rem 0;
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
