<template>
<div class="animated fadeIn">
    <streaming-worker v-if="showStreamingWorker"></streaming-worker>
    <streaming-customer v-if="showStreamingCustomer"></streaming-customer>
</div>
</template>

<script>
import StreamingWorker from './StreamingWorker'
import StreamingCustomer from './StreamingCustomer'

export default {
    name: 'Streaming',
    components: {
        'streaming-worker': StreamingWorker,
        'streaming-customer': StreamingCustomer,
    },
    data: function () {
        return {
            enableCard: false,
        }
    },
    computed: {
        showStreamingWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showStreamingCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
